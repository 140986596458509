<template>
  <div>
    <!-- <Snowfall></Snowfall> -->
    <section class="bets-menu text-center d-flex mt-1 mb-1">
      <div id="jp1" class="p-1 px-2 jp-tab j-a-1" @click="getJackpot(5)" :class="{ 'active': activeTab === 5 }">
        MB8 : MIDWEEK JACKPOT
        <sup><span class="custom-badge">NEW</span></sup>
      </div>

      <div id="jp2" class="p-1 px-2 jp-tab j-a-1" @click="getJackpot(6)" :class="{ 'active': activeTab === 6 }">
        JAZIKA : MEGA JACKPOT&nbsp;<sup><span class="custom-badge">NEW</span></sup>
      </div>

      <div class="p-1 px-2 jp-tab j-a-1 d-none">Mega Jackpot</div>
    </section>

    <section style="background-color: var(--odd-color);" v-show="matches.length > 0" class="jackpot-section-header mb-1 px-2 py-2">
      <div v-if="jackpot_category===5" class="mb-1">
        <img style="max-width: 1000px; width: 100%;" src="/img/carousels/jazika.jpg">
      </div>
      <div v-else-if="jackpot_category===6" class="mb-1">
        <img style="max-width: 1000px; width: 100%;" src="/img/carousels/jazika.jpg">
      </div>
      <div v-else class="mb-1">
        <img style="max-width: 1000px; width: 100%;" src="/img/MB8/week6.jpg">
      </div>
      <div v-if="jackpot" class="jackpot-header-wrapper p-0 text-center">
        <div class="orange-text text-center d-none">
          {{ jackpot.name }}
        </div>

        <div class="jp-anc pt-1" style="color: white; font-weight: 800;">
          <strong>WIN KSH. {{ formatCurrency(jackpot.prize) }} </strong>
        </div>

        <div class="j-a-1 small-text pb-3" style="font-weight: 600;">
          CLOSES on {{ formatMatchDate(jackpot.end) }} <br><br>
          <span v-if="jackpot_category===5" style="border: 1px solid var(--secondary); border-radius: 5px; padding: 5px; background-color: var(--secondary);">STAKE ni 49/- TU!</span>
          <span v-else-if="jackpot_category===6" style="border: 1px solid var(--secondary); border-radius: 5px; padding: 5px; background-color: var(--secondary);">STAKE ni 99/- TU!</span>
        </div>

      </div>
    </section>

    <div v-if="loading">
      <ShimmerJackpot></ShimmerJackpot>
    </div>

    <div v-else>
      <JackpotGames
          v-for="(match, index) in matches"
          v-bind:key="getLeagueKey(index)"
          v-bind:current_row="index"
          v-bind:fixture="match"></JackpotGames>

      <div v-if="jackpot_category===6">
        <Triviahunt v-if="shouldDisplayTrivia" :triviaLink="triviaLink"></Triviahunt>
      </div>
    </div>

    <div class="text-center" v-if="matches.length === 0">
      <!-- <br>
      <h1 class="">Results</h1> -->
      <div v-if="jackpot_category===5" class="fun-text" id="the-final-countdown">
        <div class="">
          <router-link to="/jackpot/JackpotResultsMB8">
            <button style="background-color: green; color: white; border-radius: 5px;">
              Jackpot Results
            </button>
          </router-link>
        </div><br>
        <svg fill="#000000" width="64px" height="64px" viewBox="0 0 32 32" version="1.1"
             xmlns="http://www.w3.org/2000/svg">
          <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
          <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
          <g id="SVGRepo_iconCarrier">
            <title>warning</title>
            <path
                d="M30.555 25.219l-12.519-21.436c-1.044-1.044-2.738-1.044-3.782 0l-12.52 21.436c-1.044 1.043-1.044 2.736 0 3.781h28.82c1.046-1.045 1.046-2.738 0.001-3.781zM14.992 11.478c0-0.829 0.672-1.5 1.5-1.5s1.5 0.671 1.5 1.5v7c0 0.828-0.672 1.5-1.5 1.5s-1.5-0.672-1.5-1.5v-7zM16.501 24.986c-0.828 0-1.5-0.67-1.5-1.5 0-0.828 0.672-1.5 1.5-1.5s1.5 0.672 1.5 1.5c0 0.83-0.672 1.5-1.5 1.5z">
            </path>
          </g>
        </svg>
        <p>The Next MB8 Jackpot Starts In A Bit</p>
      </div>
      <div v-if="jackpot_category===6" class="fun-text" id="the-final-countdown">
        <div class="">
          <router-link to="jackpot/JackpotResultsMB15">
            <button style="background-color: green; color: white; border-radius: 5px;">
              Jackpot Results
            </button>
          </router-link>
        </div><br>
        <svg fill="#000000" width="64px" height="64px" viewBox="0 0 32 32" version="1.1"
             xmlns="http://www.w3.org/2000/svg">
          <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
          <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
          <g id="SVGRepo_iconCarrier">
            <title>warning</title>
            <path
                d="M30.555 25.219l-12.519-21.436c-1.044-1.044-2.738-1.044-3.782 0l-12.52 21.436c-1.044 1.043-1.044 2.736 0 3.781h28.82c1.046-1.045 1.046-2.738 0.001-3.781zM14.992 11.478c0-0.829 0.672-1.5 1.5-1.5s1.5 0.671 1.5 1.5v7c0 0.828-0.672 1.5-1.5 1.5s-1.5-0.672-1.5-1.5v-7zM16.501 24.986c-0.828 0-1.5-0.67-1.5-1.5 0-0.828 0.672-1.5 1.5-1.5s1.5 0.672 1.5 1.5c0 0.83-0.672 1.5-1.5 1.5z">
            </path>
          </g>
        </svg>
        <p>The Next JAZIKA MEGA Jackpot Starts In A Bit</p>
      </div>
    </div>

<!--    <BottomNavigation v-bind:games="jackpot.games"
                      v-bind:stake="jackpot.stake" v-bind:jackpot_id="jackpotID" v-bind:jackpot_category="jackpot_category"></BottomNavigation>-->

    <BottomNavigation v-if="jackpot_category === 5"
                      v-show="matches.length > 0"
                      v-bind:jackpot="jackpot"
                      v-bind:games="jackpot.games"
                      v-bind:jackpot_id="jackpotID"
                      v-bind:matches="jackpot.matches"
                      v-bind:stake="jackpot.stake"
                      v-bind:jackpot_category="jackpot_category">

    </BottomNavigation>

    <BottomNavigation2 v-if="jackpot_category === 6"
                       v-show="matches.length > 0"
                       v-bind:jackpot="jackpot"
                       v-bind:games="jackpot.games"
                       v-bind:matches="jackpot.matches"
                       v-bind:jackpot_id="jackpotID"
                       v-bind:stake="jackpot.stake"
                      v-bind:jackpot_category="jackpot_category">
    </BottomNavigation2>
  </div>
</template>

<script>
// import axios from "@/services/api";
import jpserve from "@/services/jpserve";
import JackpotGames from "./JackpotGames";
// import Snowfall from "@/components/Snowfall.vue";
import BottomNavigation2 from "./BottomNavigation2";
import BottomNavigation from "./BottomNavigation";
import Triviahunt from "@/components/Triviahunt.vue";
import ShimmerJackpot from "../shimmer/ShimmerJackpot.vue";
export default {
  
  name: "Jackpot",
  metaInfo() {
    return {
      title: 'Jackpot games in Kenya | FalconBet',
      meta: [
        {
          name: 'description',
          content: 'Play jackpot games in Kenya by registering at FalconBet. Login today and enjoy Wabebe or MB8 jackpot. ',
        },
        {
          name: 'keywords',
          content: 'FalconBet, sports betting, online betting,Jackpot games, Jackpot, FalconBet Jackpot, FalconBet Wabebe, FalconBet MB8, MB8, Wabebe, betting experience',
        },
        { canonical: 'https://FalconBet.com/jackpot-games' },
      ],
    };
  },
  components: {
    ShimmerJackpot,
    JackpotGames,
    Triviahunt,
    BottomNavigation,
    BottomNavigation2,
    // Snowfall,
  },
  data: function () {
    return {
      end: "",
      countDown: "",
      matches: [],
      details: [],
      results: [],
      jackpot_category: '',
      prev: [],
      sub_page: "",
      triviaLink: "https://qz.app.do/FalconBet-supa-sato-trivia-10",
      key: "1",
      activeTab: 5,
      stake_type: '',
      isActive: false,
      activeClass: "active",
      view_more: false,
      loading: true,
      btn: "",
      jackpot: {},
      loadingMessage: "",
      retries: 0,
      active_jp: 1,
      games_length: 0,
      jackpots: [],
      jackpotID: ''
    };
  },
  methods: {
    makeActive: function (item) {
      // When a model is changed, the view will be automatically updated.
      this.active = item;
    },

    autoPick: function () {
      this.picked_button = "auto";

      this.clearJackpotBetSlip();
      var max = this.matches.length;
      var r = 0;
      while (r < max) {
        // generate random between 0 and 2
        var i = this.getRandomInt(3);
        var id = "r" + r + "c" + i;
        //// console.log('Wants to click '+id);
        //this.jQuery("#"+id).click();
        this.jQuery("[unique=" + id + "]").click();
        r++;
      }
    },
    getLeagueKey: function (index) {
      return Math.random()
          .toString(10)
          .replace("0.", "competition-id-" + index + "-");
    },
    updateCountdown() {
      const currentTime = new Date();
      const targetTime = new Date(this.jackpotEndDate);

      const timeRemaining = targetTime - currentTime;

      if (timeRemaining <= 0) {
        // Countdown has reached zero or gone below zero
        this.countDown = "Jackpot Has Started!";
      } else {
        // Format and display the remaining time
        const hours = Math.floor((timeRemaining / (60 * 60 * 1000)) % 24);
        const minutes = Math.floor((timeRemaining / (60 * 1000)) % 60);
        const seconds = Math.floor((timeRemaining / 1000) % 60);

        this.countDown = `${hours}h ${minutes}m ${seconds}s`;
      }
    },

    getOutcomeKey: function (index) {
      return Math.random()
          .toString(10)
          .replace("0.", "outcome-" + index + "-");
    },

    getJackpots: function (stakeType) {
      var vm = this;
      var path = process.env.VUE_APP_BASE_JACKPOT_URL + '/jackpot/categories';

      // Assuming stake_type is a parameter that needs to be sent to the server
      var requestData = {
        stake_type: stakeType
      };

      jpserve.get(path, JSON.stringify(requestData))
          .then((res) => {
            // console.log("Payload from GetJackpots", res);
            var games = res.data.message;
            vm.jackpots = games;
            vm.autoRefreshJackpotUI(vm.$vnode.tag);

            if (vm.jackpots == null) {
              this.loading = false;
              this.loadingMessage = "Jackpot coming soon!";
            } else {
              this.getJackpot(stakeType);
            }
          })
          .catch(() => {
            if (this.retries < 3) {
              this.retries = this.retries + 1;
              // Retry with the same stakeType
              this.getJackpots(stakeType);
            } else {
              // console.error(err);
              this.loading = false;
              this.loadingMessage = "An error occurred";
            }
          });
    },

    getKey: function (index) {
      return Math.random()
          .toString(10)
          .replace("0.", "unique-id-" + index + "-");
    },

    getJackpot: function (category_id) {
      this.loading = true;
      this.activeTab = category_id;
      this.jackpot_category = category_id;
      this.matches = [];

      var vm = this;

      const path = `${process.env.VUE_APP_BASE_JACKPOT_URL}/jackpot/${category_id}`;
      this.loadingMessage = "Loading Jackpot Games...";

      jpserve.get(path, JSON.stringify({}))
          .then((res) => {

            // console.log("Check Jackpot data", res.data)

            vm.$store.dispatch('setJackPotID', res.data.id);
            vm.jackpotID = res.data.id;
            vm.games_length = res.data.number_of_games;
            var games = res.data.fixture;
            vm.matches = games;
            // console.log("Matches", games)
            vm.jackpot = res.data;
            // console.log("Jackpot data", vm.jackpot)
            vm.jackpot.games = games.length;
            vm.autoRefreshJackpotUI(vm.$vnode.tag);
            // console.log("games length " + games.length);
            if (parseInt(games.length) != 0) {
              this.loadingMessage = "Jackpot coming soon!";
            } else {
              this.loadingMessage = "";
            }
            this.loading = false;
          })
          .catch(() => {
            // console.error("Error fetching jackpot data:", err);
            this.loading = false;
            this.loadingMessage = "An error occurred";
          });
    },

    getColor: function (home, away) {
      if (home > away) {
        return "btn btn-success";
      } else if (home < away) {
        return "btn btn-danger";
      } else {
        return "btn";
      }
    },

    getJackpotResult: function (category_id) {
      var vm = this;
      var path = process.env.VUE_APP_BASE_JACKPOT_URL + '/jackpot/{category_id}/results';
      path = path.replace("{category_id}", category_id)

      jpserve.get(path, JSON.stringify({})).then((res) => {
        var games = res.data.message;
        vm.results = games;
      });
    },

    addZero: function (i) {
      if (i < 10) {
        i = "0" + i;
      }
      return i;
    },

    viewResult: function (prev) {
      this.view_more = true;

      this.prev = prev;
    },
    getHomeCompetitorName: function (matchName) {
      if (matchName === undefined) {
        return "";
      }

      return matchName.split("vs.")[0];
    },
    getAwayCompetitorName: function (matchName) {
      if (matchName === undefined) {
        return "";
      }
      return matchName.split("vs.")[1];
    },

  },
  mounted() {
    this.$store.dispatch("setCurrentPage", "jackpot");
    this.reloadProfile();
    this.getJackpot(5);
    this.getJackpot(6);
    this.getJackpots();
    this.getBonus();
    this.updateCountdown();
    setInterval(this.updateCountdown, 1000);

    document.getElementById('app').scrollIntoView({ behavior: 'smooth' });

    this.getJackpotResult();

    var vm = this;

    this.loading = false;
    setTimeout(function () {
      vm.autoRefreshJackpotUI("setInterval");
    }, 2000);
  },

  computed: {
    current_sub_page: function () {
      return this.$store.state.current_sub_page;
    },
    current_page: function () {
      return this.$store.state.current_page;
    },
    shouldDisplayTrivia() {
      const now = new Date();
      const startDate = new Date("2024-04-20T11:00:00");
      const endDate = new Date("2024-04-20T16:30:00");

      return (
          now >= startDate &&
          now <= endDate
      );
    },
  },
};
</script>