<template>

  <section class="shimmer-section px-2">
      <div  class="header-box shine mb-2"></div>
      <div v-for="index in 10" :key="index" class="shimmer-wrapper mb-2">
      <div class="row m-0 p-0 mb-2">
          <div class="col-6 p-0">
              <div class="lines shine line-competition-left"></div>
          </div>
          <div class="col-6 p-0 text-right">
              <div class="lines shine line-competition-right"></div>
          </div>
      </div>

      <div class="row m-0 p-0 mb-0">
        <div class="col-6 p-0 d-block">
            <div class="lines shine line-team-name"></div><br>
            <div class="lines shine line-team-name"></div>
        </div>
        <div class="col-6 p-0">
            <div  class="box shine "></div>
        </div>
      </div>
     
      </div>

    
  </section>

</template>
<style scoped>
.shine {
  background: var(--lightest-gray1);
  background-image: linear-gradient(to right, var(--lightest-gray1) 0%, #23313D 20%, var(--lightest-gray1) 40%, var(--lightest-gray1) 100%);
  background-repeat: no-repeat;
  background-size: 800px 104px; 
  display: inline-block;
  position: relative; 
  
  -webkit-animation-duration: 1s;
  -webkit-animation-fill-mode: forwards; 
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-name: placeholderShimmer;
  -webkit-animation-timing-function: linear;
  }

.box {
  height: 40px;
  width: 100%;
  border-radius: 5px;
}
.header-box{
    height: 100px;
  width: 100%;
  border-radius: 5px;
}

.lines {
  height: 10px;
  width: 100%; 
}
.line-competition-left{
    width: 60%; 
}
.line-competition-right{
    width: 60%; 
    margin-right: 0px;
}
.line-team-name{
    width: 50%; 
    height: 15px;
    margin-bottom: 3px;
}

.line-team-markets{
    width: 20%; 
    height: 10px;
    margin-bottom: 3px;
}
photo {
  display: block!important;
  width: 325px; 
  height: 100px; 
  margin-top: 15px;
}

@-webkit-keyframes placeholderShimmer {
  0% {
    background-position: -468px 0;
  }
  
  100% {
    background-position: 468px 0; 
  }
}


</style>
<script>
export default {
  name: "ShimmerJackpot",
}
</script>