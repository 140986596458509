<template>

  <section class="shimmer-section px-2">
      <div class="header-wrapper text-center mb-2">
        <span class="shine lines lines-small"></span> <br>
        <span class="shine liness lines-big"></span> <br>
        <span class="shine lines lines-smaller"></span>
      </div>

      <div v-for="index in 10" :key="index" class="shimmer-wrapper mb-1">
      <div class="row m-0 p-0 mb-1">
          <div class="col-6 p-0">
              <div class="lines shine line-competition-left"></div>
          </div>
      </div>
      <div class="box shine">

      </div>

      </div>

    
  </section>

</template>
<style scoped>
.shine {
  background: var(--lightest-gray1);
  background-image: linear-gradient(to right, var(--lightest-gray1) 0%, #23313D 20%, var(--lightest-gray1) 40%, var(--lightest-gray1) 100%);
  background-repeat: no-repeat;
  background-size: 800px 104px; 
  display: inline-block;
  position: relative; 
  
  -webkit-animation-duration: 1s;
  -webkit-animation-fill-mode: forwards; 
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-name: placeholderShimmer;
  -webkit-animation-timing-function: linear;
}

.box {
  height: 40px;
  width: 100%;
  border-radius: 0px;
}


.lines {
  height: 10px;
  width: 100%; 
}
.liness {
  height: 15px;
  width: 100%; 
}
.lines-small{
    width: 40%; 
}
.lines-smaller{
    width: 30%; 
}
.lines-big{
    width: 60%; 
}
.line-competition-right{
    width: 60%; 
    margin-right: 0px;
}
.line-team-name{
    width: 50%; 
    height: 15px;
    margin-bottom: 3px;
}

.line-team-markets{
    width: 20%; 
    height: 10px;
    margin-bottom: 3px;
}
photo {
  display: block!important;
  width: 325px; 
  height: 100px; 
  margin-top: 15px;
}

@-webkit-keyframes placeholderShimmer {
  0% {
    background-position: -468px 0;
  }
  
  100% {
    background-position: 468px 0; 
  }
}


</style>
<script>
export default {
  name: "ShimmerMarkets",
}
</script>